import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <div className="product-area ptb-90">
          <div className="container">
              <h1>Oi? Que página é essa?</h1>
              <p className='text-danger'>Essa página que você acessou não existe!.</p>
          </div>
      </div>


  </Layout>
)

export default NotFoundPage
